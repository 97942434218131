<template>
  <div class="modal fade" tabindex="-10" id="kt_modal_add_procedimento">
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <Form
        class="form"
        @submit="onSubmit"
        :validation-schema="schema"
        @invalid-submit="onInvalidSubmit"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Inserimento nuovo procedimento</h5>

            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>
          <div class="modal-body">
            <div class="card-body p-6">
              <div
                class="alert alert-danger"
                role="alert"
                v-show="alertCampiObb"
              >
                Compila i campi obbligatori
              </div>
              <div class="row pb-4">
                <div class="col-lg-3 filter">
                  <label class="fw-bold text-gray-700">Stagione*</label>
                  <SelectInput
                    :options="stagioni"
                    name="anno"
                    :value="anno"
                    @changeSelect="setStagione($event)"
                  />
                </div>
                <div class="col-lg-4 filter">
                  <label class="fw-bold text-gray-700"
                    >Cod. procedimento*</label
                  >
                  <Field
                    name="num_Proc"
                    type="text"
                    v-model="num_Proc"
                    class="form-control"
                  />
                  <ErrorMessage
                    class="help-message"
                    style="color: red"
                    name="num_Proc"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="closeModalProc"
            >
              Chiudi
            </button>
            <button
              type="submit"
              class="badge bg-secondary rounded blueFit fs-6"
              :disabled="disableButton"
            >
              Avanti
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { addProcedimento } from "@/requests/sanzioniDisciplinariRequests";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default {
  name: "add-procedimento-modal",
  components: { Loading, SelectInput, Form, Field, ErrorMessage },
  setup() {
    const store = useStore();
    const router = useRouter();

    const alertCampiObb = ref(false);

    const anno = ref(new Date().getFullYear);
    const num_Proc = ref(null);

    const setStagione = (event) => {
      anno.value = event;
    };

    const schema = yup.object().shape({
      anno: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable(),
      num_Proc: yup.string().nullable().required("Obbligatorio"),
    });

    const disableButton = ref(false);
    const isLoading = ref(false);

    const onSubmit = (values) => {
      disableButton.value = true;
      isLoading.value = true;
      const responseAdd = ref({});
      responseAdd.value = addProcedimento(values);
      responseAdd.value.then((value) => {
        if (value.status == 200) {
          window.location.href = `/sanzioni/visualizza-procedimento/${value.data.id}`;
          //router.push(`/sanzioni/visualizza-procedimento/${value.data.id}`);
        } else {
          const strMsg = ref(
            value.data.message
              ? value.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
          Swal.fire({
            html: strMsg.value,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        }
        disableButton.value = false;
        isLoading.value = false;
      });
    };

    const onInvalidSubmit = () => {
      alertCampiObb.value = true;
    };

    const resetField = () => {
      alertCampiObb.value = false;
      anno.value = new Date().getFullYear;
      num_Proc.value = null;
    };

    return {
      stagioni: computed(() => store.getters.stagioni),
      schema,
      alertCampiObb,
      anno,
      num_Proc,
      setStagione,
      onInvalidSubmit,
      onSubmit,
      resetField,
      disableButton,
      isLoading,
    };
  },
};
</script>

<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
    margin-top: 5rem;
  }
}

@media (max-width: 575.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
    margin-top: 4rem;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>
