<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div class="row mt-5">
      <div class="col-4">
        <div v-if="!readOnly">
          <button
            type="button"
            class="btn btn-light text-gray-700 fs-6 text-start"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_add_procedimento"
          >
            <i class="bi bi-plus fs-6 text-gray-700"></i>aggiungi procedimento
          </button>
          <AddProcedimento />
        </div>
      </div>
      <div class="col-4 text-center">
        <div class="btn btn-light text-gray-700 fs-6 text-center">
          <input
            class="form-check-input me-2"
            type="checkbox"
            @click="setTutteLeStagioniSanzioni"
            :checked="tutteStagioniSanzioni"
          />visualizza tutte le stagioni
        </div>
      </div>
      <div class="col-4">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzate
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          procedimenti di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        sanzioni_list.length === 0 && loaded && (status == 200 || status == 204)
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click.prevent="$emit('resetFilters')"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="sanzioni_list"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsSanzioni"
        @current-change="setCurrentPageSanzioni"
      >
        <template v-slot:cell-anno="{ row: data }">{{ data.anno }} </template>
        <template v-slot:cell-num_proc="{ row: data }"
          >{{ data.num_proc }}
        </template>
        <template v-slot:cell-data_sentenza="{ row: data }"
          ><div class="text-center">{{ data.data_sentenza }}</div>
        </template>
        <template v-slot:cell-siglaOrgano="{ row: data }"
          >{{ data.siglaOrgano }}
        </template>
        <template v-slot:cell-comitato="{ row: data }"
          >{{ data.comitato }}
        </template>
        <template v-slot:cell-causa="{ row: data }">{{ data.causa }} </template>
        <template v-slot:cell-societa="{ row: data }"
          ><div
            class="tab-long-xl pt-1 pb-1"
            v-for="(sanzione, index) in data.sanzioni"
            :key="index"
          >
            {{ sanzione.societa }} -
            {{ sanzione.cod_affiliazione }}
          </div>
        </template>
        <template v-slot:cell-ammende="{ row: data }">
          <div
            class="pt-1 pb-1 text-end"
            v-for="(sanzione, index) in data.sanzioni"
            :key="index"
          >
            <span v-if="sanzione.ammenda"
              >{{ sanzione.ammenda.toFixed(2) }} €</span
            >
            <span v-else>-</span>
          </div>
        </template>
        <template v-slot:cell-stato_pagamento="{ row: data }">
          <div
            class="pt-1 pb-1"
            v-for="(sanzione, index) in data.sanzioni"
            :key="index"
          >
            <h1
              v-show="sanzione.id_stato_pagamento == 3"
              class="badge badge-light-success"
            >
              Approvato
            </h1>
            <h1
              v-show="sanzione.id_stato_pagamento == 2"
              class="badge badge-light-warning"
            >
              Non approvato
            </h1>
            <h1
              v-show="sanzione.id_stato_pagamento == 1"
              class="badge badge-light-danger"
            >
              Non effettuato
            </h1>
            <h1
              v-show="sanzione.id_stato_pagamento == 4"
              class="badge badge-light"
            >
              Sanzione restrittiva
            </h1>
          </div>
        </template>
        <template v-slot:cell-options="{ row: data }">
          <div class="btn-group">
            <router-link
              :to="`/sanzioni/visualizza-procedimento/${data.id_procedimento}`"
              class="btn btn-sm dropdown p-1"
            >
              <i class="bi bi-eye text-dark fs-4"></i>
            </router-link>
            <button
              v-if="isEnabled('fnSanzioniEditProcedimento')"
              class="btn btn-sm dropdown p-1"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="bi bi-three-dots-vertical text-dark fs-4"></i>
            </button>
            <ul class="dropdown-menu">
              <router-link
                :to="`/sanzioni/visualizza-procedimento/${data.id_procedimento}`"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-pen text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7"> Modifica </span>
                    </div>
                  </div>
                </li></router-link
              >
              <a
                href="#"
                v-if="isEnabled('fnSanzioniDeleteSingolaSanzione')"
                @click="
                  idSelectedSentenza = data.id_sentenza;
                  viewEditProcedimento(data.id_procedimento);
                "
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div
                      class="navi-link"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_delete_sanzione"
                    >
                      <i class="bi bi-trash text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7">
                        Elimina sanzione
                      </span>
                    </div>
                  </div>
                </li></a
              >
              <a
                href="#"
                v-if="isEnabled('fnSanzioniDeleteTutteSanzioni')"
                @click="delSentenza(data.id_sentenza)"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <a href="#" class="navi-link">
                      <i class="bi bi-trash text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7">
                        Elimina sentenza
                      </span>
                    </a>
                  </div>
                </li></a
              >
            </ul>
          </div>
        </template>
      </Datatable>
    </div>
    <DeleteSanzione
      :sentenza="selectedSentenza"
      @getSanzioniList="$emit('getSanzioniList')"
    />
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, computed } from "vue";
import Datatable from "../../kt-datatable/KTDatatable.vue";
import AddProcedimento from "./AddProcedimentoModal.vue";
import DeleteSanzione from "./DeleteSanzione.vue";
import isEnabled from "@/composables/isEnabled.js";

import {
  detailProcedimento,
  //deleteSanzione,
  deleteSentenza,
} from "@/requests/sanzioniDisciplinariRequests";

import { alertSuccess, alertFailed } from "@/composables/swAlert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default {
  name: "TableSanzioniDisciplinari",
  components: {
    Datatable,
    AddProcedimento,
    DeleteSanzione,
    Loading,
  },
  emits: ["getSanzioniList", "resetFilters"],
  // props: {
  //   campiLocked: {
  //     type: Array,
  //     required: false,
  //   },
  // },
  setup(props, { emit }) {
    const store = useStore();

    const setTutteLeStagioniSanzioni = () => {
      store.commit("setTutteLeStagioniSanzioni");
      emit("getSanzioniList");
    };

    const setFetchRowsSanzioni = (e) => {
      store.commit("setFetchRowsSanzioni", e);
      emit("getSanzioniList");
    };
    const setCurrentPageSanzioni = (page) => {
      store.commit("setCurrentPageSanzioni", page);
      emit("getSanzioniList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnSanzioni", columnName);
      store.commit("setSortOrderSanzioni", order);
      emit("getSanzioniList");
    };

    const isLoading = ref(false);

    const delSentenza = (id) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "La sentenza verrà eliminata definitivamente.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Elimina",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await deleteSentenza(id).then((res) => {
            if (res.data.statusCode == 200) {
              alertSuccess("Sentenza eliminata correttamente");
              emit("getSanzioniList");
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
            isLoading.value = false;
          });
        }
      });
    };

    const tableHeader = ref([
      {
        name: "Stagione",
        key: "anno",
      },
      {
        name: "Cod. procedimento",
        key: "num_proc",
      },
      {
        name: "Data decisione",
        key: "data_sentenza",
        align: "center",
      },
      {
        name: "Organo emittente",
        key: "siglaOrgano",
      },
      {
        name: "Comitato",
        key: "comitato",
      },
      {
        name: "Causa",
        key: "causa",
      },
      {
        name: "Società",
        key: "societa",
      },
      {
        name: "Ammende",
        key: "ammende",
        sortable: false,
        align: "end",
      },
      {
        name: "Stato Pagamento",
        key: "stato_pagamento",
        sortable: false,
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    const isLoaded = ref(false);

    const selectedProcedimento = ref({});
    const idSelectedSentenza = ref();
    const selectedSentenza = ref({});

    const viewEditProcedimento = (id) => {
      detailProcedimento(id).then((res) => {
        selectedProcedimento.value = res.results[0];

        selectedProcedimento.value.sentenze.forEach((sentenza) => {
          if (sentenza.id_sentenza == idSelectedSentenza.value) {
            selectedSentenza.value = { ...sentenza };
          }
        });
      });
    };

    return {
      sanzioni_list: computed(() =>
        store.getters.getStateFromName("resultssanzioni_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedsanzioni_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordsanzioni_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statussanzioni_list")
      ),
      tutteStagioniSanzioni: computed(
        () => store.getters.tutteStagioniSanzioni
      ),
      setTutteLeStagioniSanzioni,
      currentPage: computed(() => store.getters.currentPageSanzioni),
      rowsToSkip: computed(() => store.getters.rowsToSkipSanzioni),
      fetchRows: computed(() => store.getters.fetchRowsSanzioni),
      sortColumn: computed(() => store.getters.sortColumnSanzioni),
      sortOrder: computed(() => store.getters.sortOrderSanzioni),

      isLoaded,
      setFetchRowsSanzioni,
      setCurrentPageSanzioni,
      setSortOrderColumn,
      tableHeader,
      viewEditProcedimento,
      delSentenza,
      selectedProcedimento,
      idSelectedSentenza,
      selectedSentenza,
      isLoading,
      isEnabled,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
    };
  },
};
</script>

<style scoped></style>
