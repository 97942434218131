<template>
  <div>
    <div class="card shadow">
      <form autocomplete="off">
        <div
          class="pt-4 pb-4 pe-4 ps-4"
          @keyup.enter="$emit('getSanzioniList')"
        >
          <div class="row mb-4">
            <div class="col-sm-3 filter">
              <input
                type="text"
                class="form-control"
                placeholder="Cod. procedimento"
                aria-label=""
                :value="codProcedimentoSanzioni"
                @input="setProcedimentoSanzioni"
              />
            </div>
            <div class="col-sm-3 filter">
              <input
                type="text"
                class="form-control"
                placeholder="Numero decisione"
                aria-label=""
                :value="numDecisioneSanzioni"
                @input="setNumDecisioneSanzioni"
              />
            </div>
            <div class="col-sm-3 filter">
              <SelectInput
                :options="sanzioni_tipo"
                name="sanzioni_tipo"
                placeholder="Tipo sanzione"
                :value="idTipoSanzioni"
                @changeSelect="setIdTipoSanzioni($event)"
              />
            </div>
            <div class="col-sm-3 filter">
              <SelectInput
                :options="sanzioni_stato_pagamento"
                name="sanzioni_stato_pagamento"
                placeholder="Stato pagamento"
                :value="idStatoPagamentoSanzioni"
                @changeSelect="setIdStatoPagamentoSanzioni($event)"
              />
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-sm-6 filter">
              <div class="dp__input_wrap text-gray-600">
                <input
                  placeholder="Società"
                  name="denominazione_societa"
                  type="text"
                  class="form-control"
                  @input="
                    inputFocus = 'societa';
                    getSocieta($event.target.value);
                  "
                  :value="societaDenominazioneProcedimento"
                  autocomplete="off"
                />
                <svg
                  @click="
                    setIdSocietaSanzioni({ name: null, value: null });
                    setIdPersonaSanzioni({ name: null, value: null });
                  "
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  class="dp__icon dp__clear_icon dp__input_icons"
                >
                  <path
                    d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                  ></path>
                  <path
                    d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                  ></path>
                </svg>
              </div>
              <ListSocieta
                :focusOnInput="inputFocus === 'societa'"
                :list="societa"
                @hideList="
                  inputFocus = null;
                  resetSocieta;
                "
                @selectedSocieta="
                  resetSocieta();
                  setIdSocietaSanzioni($event);
                  inputFocus = null;
                "
              ></ListSocieta>
            </div>
            <div class="col-sm-6 filter">
              <div class="dp__input_wrap text-gray-600">
                <input
                  placeholder="Persona"
                  name="nominativo_completo"
                  type="text"
                  class="form-control"
                  @input="
                    inputFocus = 'persona';
                    getPersone($event.target.value);
                  "
                  :value="personaDenominazioneProcedimento"
                  autocomplete="off"
                />
                <svg
                  @click="setIdPersonaSanzioni({ name: null, value: null })"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  class="dp__icon dp__clear_icon dp__input_icons"
                >
                  <path
                    d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                  ></path>
                  <path
                    d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                  ></path>
                </svg>
              </div>
              <ListTesserati
                v-if="inputFocus === 'persona'"
                :list="persone"
                @hideList="
                  inputFocus = null;
                  resetPersone;
                "
                @selectedTesserato="
                  resetPersone();
                  setIdPersonaSanzioni($event);
                  inputFocus = null;
                "
              ></ListTesserati>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3 filter">
              <Datepicker
                v-model="inizioDataDecSanzioni"
                placeholder="Data decisione da"
                :format="format"
                :monthChangeOnScroll="false"
                :enableTimePicker="false"
                inputClassName="h-28"
                @update:modelValue="setInizioDataDecSanzioni($event)"
                autoApply
              />
            </div>
            <div class="col-sm-3">
              <Datepicker
                v-model="fineDataDecSanzioni"
                placeholder="Data decisione a"
                :format="format"
                :monthChangeOnScroll="false"
                :enableTimePicker="false"
                inputClassName="h-28"
                @update:modelValue="setFineDataDecSanzioni($event)"
                autoApply
              />
            </div>
          </div>

          <div class="text-center mt-5">
            <button
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="$emit('getSanzioniList')"
              :disabled="!loaded"
            >
              Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
            </button>
            <span
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="$emit('resetFilters')"
            >
              <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
            ></span>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, watch, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import ListTesserati from "../utility/ListTesserati.vue";
import ListSocieta from "../utility/ListSocieta.vue";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";
import { format } from "@/composables/formatDate";

export default defineComponent({
  name: "filtri-sanzioni-disciplinari",
  emits: ["resetFilters", "getSanzioniList"],
  // props: {
  //   campiLocked: {
  //     type: Array,
  //     required: true,
  //   },
  // },
  components: {
    ListTesserati,
    ListSocieta,
    SelectInput,
    Datepicker,
  },
  setup(props, { emit }) {
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    //lookup
    /*  const sanzioni_stato = computed(() =>
      store.getters.getStateFromName("sanzioni_stato")
      sst
    ); */

    /* const format = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    }; */

    const sanzioni_tipo = computed(() =>
      store.getters.getStateFromName("sanzioni_tipo")
    );

    const keys = ref("stip");
    if (!sanzioni_tipo.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const idSocietaSanzioni = computed(() => store.getters.idSocietaSanzioni);
    const idPersonaSanzioni = computed(() => store.getters.idPersonaSanzioni);
    const codProcedimentoSanzioni = computed(
      () => store.getters.codProcedimentoSanzioni
    );
    const numDecisioneSanzioni = computed(
      () => store.getters.numDecisioneSanzioni
    );
    const idTipoSanzioni = computed(() => store.getters.idTipoSanzioni);
    const idStatoPagamentoSanzioni = computed(
      () => store.getters.idStatoPagamentoSanzioni
    );
    const inizioDataDecSanzioni = computed(
      () => store.getters.inizioDataDecSanzioni
    );
    const fineDataDecSanzioni = computed(
      () => store.getters.fineDataDecSanzioni
    );

    const societaDenominazioneProcedimento = computed(
      () => store.getters.societaDenominazioneProcedimento
    );
    const personaDenominazioneProcedimento = computed(
      () => store.getters.personaDenominazioneProcedimento
    );

    const setIdSocietaSanzioni = ({ value, name }) => {
      store.commit("setIdSocietaSanzioni", value);
      store.commit("setSocietaDenominazioneProcedimento", name);
    };

    const setIdPersonaSanzioni = ({ value, name }) => {
      store.commit("setIdPersonaSanzioni", value);
      store.commit("setPersonaDenominazioneProcedimento", name);
    };

    const setProcedimentoSanzioni = (e) => {
      const string = e.target.value;
      store.commit("setProcedimentoSanzioni", string);
    };

    const setNumDecisioneSanzioni = (e) => {
      const string = e.target.value;
      store.commit("setNumDecisioneSanzioni", string);
    };

    const setIdTipoSanzioni = (event) => {
      store.commit("setIdTipoSanzioni", event);
    };

    const setIdStatoPagamentoSanzioni = (event) => {
      store.commit("setIdStatoPagamentoSanzioni", event);
    };

    const setInizioDataDecSanzioni = (event) => {
      store.commit("setInizioDataDecSanzioni", event);
    };

    const setFineDataDecSanzioni = (event) => {
      store.commit("setFineDataDecSanzioni", event);
    };

    const stagione = computed(() => store.getters.stagioneSelected);
    watch(stagione, () => {
      emit("getSanzioniList");
    });

    const getSocieta = (string) => {
      store.commit("setSocietaDenominazioneProcedimento", string);
      if (string === "") {
        store.commit("emptySocietaNomeCodAff");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getSocietaNomeCodAff", string);
    };
    const resetSocieta = () => {
      store.commit("emptySocietaNomeCodAff");
    };

    const getPersone = (string) => {
      store.commit("setPersonaDenominazioneProcedimento", string);
      if (string === "") {
        store.dispatch("svuotaPersoneNomeCognome");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getPersoneSanzioni", {
        string,
        id_societa: idSocietaSanzioni.value,
        stagione: stagione.value,
      });
    };
    const resetPersone = () => {
      store.dispatch("svuotaPersoneNomeCognome");
    };

    const inputFocus = ref(null);

    return {
      inputFocus,
      format,
      getSocieta,
      resetSocieta,
      getPersone,
      resetPersone,
      persone: computed(() => store.getters.personeSocieta),
      societa: computed(() => store.getters.societaNomeCodAff),
      societaDenominazioneProcedimento,
      personaDenominazioneProcedimento,
      stagione,
      idSocietaSanzioni,
      idPersonaSanzioni,
      codProcedimentoSanzioni,
      numDecisioneSanzioni,
      idTipoSanzioni,
      idStatoPagamentoSanzioni,
      inizioDataDecSanzioni,
      fineDataDecSanzioni,
      setIdSocietaSanzioni,
      setIdPersonaSanzioni,
      setProcedimentoSanzioni,
      setNumDecisioneSanzioni,
      setIdTipoSanzioni,
      setIdStatoPagamentoSanzioni,
      setInizioDataDecSanzioni,
      setFineDataDecSanzioni,
      sanzioni_tipo,
      sanzioni_stato_pagamento: [
        { id: "3", label: "Pagamento approvato" },
        { id: "2", label: "Pagamento effettuato" },
        { id: "1", label: "Pagamento non effettuato" },
        { id: "4", label: "Sanzione restrittiva" },
      ],
      loaded: computed(() =>
        store.getters.getStateFromName("loadedsanzioni_list")
      ),
    };
  },
});
</script>

<style></style>
