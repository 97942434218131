<template>
  <div class="modal fade" tabindex="-10" id="kt_modal_delete_sanzione">
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Elimina sanzioni</h5>
          <div
            class="btn btn-icon btn-sm btn-active-secondary"
            style="border: 0; !important"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="bi bi-x-lg"> </i>
          </div>
        </div>
        <div class="modal-body">
          <div class="card-body">
            <div class="ps-5 pe-5 fw-bold" v-if="sentenza">
              <div
                class="form-check row pb-4"
                v-for="(sanzione, index) in sentenza.sanzioni"
                :key="index"
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault"
                  :value="sanzione.id_sanzione"
                  @click="selectedSanzione = sanzione.id_sanzione"
                />
                <label class="form-check-label" for="flexRadioDefault1">
                  {{ sanzione.societa }} <br />
                  ammenda:
                  <span v-if="sanzione.ammenda"> {{ sanzione.ammenda }} €</span
                  ><span v-else>0.00 €</span></label
                >
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <span
            type="button"
            class="me-3 badge bg-light-danger rounded text-danger fs-6"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="closeModalSanDel"
          >
            Chiudi
          </span>
          <span
            type="button"
            class="badge bg-secondary rounded blueFit fs-6"
            @click="delSanzione(selectedSanzione)"
          >
            Elimina
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { alertSuccess, alertFailed } from "@/composables/swAlert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import { deleteSanzione } from "@/requests/sanzioniDisciplinariRequests";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default defineComponent({
  name: "DeleteSanzione",
  components: { Loading },
  emits: ["getSanzioniList"],
  props: {
    sentenza: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const selectedSanzione = ref(null);

    const isLoading = ref(false);

    const delSanzione = (id) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "La sanzione verrà eliminata definitivamente.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Elimina",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await deleteSanzione(id).then((res) => {
            if (res.data.statusCode == 200) {
              alertSuccess("Sanzione eliminata correttamente");
              emit("getSanzioniList");
              document.getElementById("closeModalSanDel").click();
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
            isLoading.value = false;
          });
        }
      });
    };

    return {
      selectedSanzione,
      delSanzione,
      isLoading,
    };
  },
});
</script>

<style scoped>
.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(5%, -50%);
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 0px;
  width: 0px;
}

@media (max-width: 1200px) {
  .modal-dialog {
    max-width: 55%;
  }
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 65%;
  }
}
</style>
