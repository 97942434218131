<template>
  <div>
    <FilterSanzioniDisciplinari
      @getSanzioniList="searchSanzioniList"
      @resetFilters="resetFilters"
    />
    <TableSanzioniDisciplinari
      @getSanzioniList="getSanzioniList"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { trimInput } from "@/composables/trimInput";

import FilterSanzioniDisciplinari from "../../components/components-fit/sanzioni-disciplinari/FilterSanzioniDisciplinari.vue";
import TableSanzioniDisciplinari from "../../components/components-fit/sanzioni-disciplinari/TableSanzioniDisciplinari.vue";

export default defineComponent({
  name: "sanzioni",
  components: { FilterSanzioniDisciplinari, TableSanzioniDisciplinari },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Sanzioni Disciplinari", []);
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const anno = computed(() => store.getters.stagioneSelected);
    const rowsToSkip = computed(() => store.getters.rowsToSkipSanzioni);
    const fetchRows = computed(() => store.getters.fetchRowsSanzioni);
    const sortColumn = computed(() => store.getters.sortColumnSanzioni);
    const sortOrder = computed(() => store.getters.sortOrderSanzioni);
    const tutteStagioniSanzioni = computed(
      () => store.getters.tutteStagioniSanzioni
    );

    const cod_Procedimento = computed(
      () => store.getters.codProcedimentoSanzioni
    );
    const num_Decisione = computed(() => store.getters.numDecisioneSanzioni);
    const id_Societa = computed(() => store.getters.idSocietaSanzioni);
    const id_Persona = computed(() => store.getters.idPersonaSanzioni);
    const cognome = computed(() => store.getters.cognomeSanzioni);
    const nome = computed(() => store.getters.nomeSanzioni);
    const id_Tipo_Sanzione = computed(() => store.getters.idTipoSanzioni);
    const id_Stato_Pagamento = computed(
      () => store.getters.idStatoPagamentoSanzioni
    );
    const data_Decisione_Da = computed(
      () => store.getters.inizioDataDecSanzioni
    );
    const data_Decisione_A = computed(() => store.getters.fineDataDecSanzioni);

    const getSanzioniList = () => {
      store.dispatch("setStoreListData", {
        keys: {
          id_Persona: id_Persona.value,
          anno: tutteStagioniSanzioni.value ? 10000 : anno.value,
          cod_Procedimento: trimInput(cod_Procedimento.value),
          num_Decisione: trimInput(num_Decisione.value),
          id_Societa: id_Societa.value,
          cognome: cognome.value,
          nome: nome.value,
          id_Tipo_Sanzione: id_Tipo_Sanzione.value,
          id_Stato_Pagamento: id_Stato_Pagamento.value,
          data_Decisione_Da: data_Decisione_Da.value,
          data_Decisione_A: data_Decisione_A.value,
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
          sortcolumn: sortColumn.value,
          sortorder: sortOrder.value,
        },
        apiLink: globalApi.SANZIONI_LIST,
        itemName: "sanzioni_list",
      });
    };

    const resetFilters = () => {
      store.commit("resetFiltersSanzioni");
      getSanzioniList();
    };

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedsanzioni_list")
    );

    const searchSanzioniList = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipSanzioni");
      getSanzioniList();
    };

    getSanzioniList();

    return {
      getSanzioniList,
      resetFilters,
      searchSanzioniList,
      tutteStagioniSanzioni,
    };
  },
});
</script>
